<template>
  <ul class="menu-nav">
    <li class="menu-section">
      <h4 class="menu-text">Messages</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/compose"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <span class="menu-text ml-10">Compose a message</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/messages"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <span class="menu-text ml-10">Message history</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/admin/messagereports"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <span class="menu-text ml-10">Usage summary</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate"> -->
    <!-- <i class="menu-icon flaticon2-expand"></i> -->
    <!-- <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link> -->

    <li class="menu-section">
      <h4 class="menu-text">Directory</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/contacts"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-expand"></i> -->
          <span class="menu-text ml-10">Manage contacts</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/lists"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-expand"></i> -->
          <span class="menu-text ml-10">Manage lists</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/contacts/upload-csv"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text ml-10">Upload CSV</span>
        </a>
      </li>
    </router-link> -->

    <!-- <li class="menu-section">
      <h4 class="menu-text">Admin</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/admin/users"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text ml-10">Users</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/admin/senders"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text ml-10">Senders</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/admin/messages"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text ml-10">Message History</span>
        </a>
      </li>
    </router-link> -->
    <!-- <router-link
      to="/admin/messageReports"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate"> -->
    <!-- <i class="menu-icon flaticon2-expand"></i> -->
    <!-- <span class="menu-text ml-10">Message Report</span>
        </a>
      </li>
    </router-link> -->
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
